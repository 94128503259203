import { ICarDetail } from '../redux/carDetails/carDetails.duck.interface';
import { IFeature, IPrice, IPriceV2 } from '../services';
import { rem } from 'polished';
import {
    IAdditionalColor,
    IAdditionalColorPrice,
    IGetPricesListRequestData,
} from '../services/financePrices/financePrices.types';
import {
    IFinanceWidgetOutput,
    IFinanceWidgetParameters,
    IFinanceWidgetParametersParsed,
    IVehicleOption,
} from '../redux/financeWidget/financeWidget.duck.interface';
import { IConfiguratorState } from '../redux/configurator/configurator.duck.interface';
import { EMPLOYEE_PRICE_TYPE, FINANCE } from '../services/filters/filters';
import { IOption } from '../services/stock/models/stockItems.service.model';
import { BRAND, isBrandAP, isMarketIT, isBrandOV, isMarketGB } from '../constants/main';
import {
    getCatalogOptionOrColorPrice,
    getCatalogOptionOrColorPriceWithoutTax,
    getTotalOptionOrColorPrice,
    getTotalOptionOrColorPriceWithoutTax,
} from './Price.utils';
import { TFunction } from 'next-i18next';
import { ReactNode } from 'react';
import { PriceRounding } from '../types/priceRounding';
import { IDeal, ITFMItem } from 'src/interfaces/Car';

enum displaySizes {
    small = 1,
    regular = 2,
    large = 3,
}

export const labelsFormattingWithFG = ({ displaySize }: { displaySize: displaySizes | undefined | null }): string => {
    switch (true) {
        case displaySize === displaySizes.small: {
            if (isBrandAP) {
                return rem('10px');
            }
            return rem('12px');
        }
        case displaySize === displaySizes.large: {
            if (isBrandAP) {
                return rem('14px');
            }
            return rem('16px');
        }
        default: {
            if (isBrandAP) {
                return rem('12px');
            }

            if (isBrandOV && !isMarketGB) {
                return rem('14px');
            }
            return rem('12.8px');
        }
    }
};

const formatPrices = (prices: IPriceV2[]): IAdditionalColorPrice[] => {
    if (!prices) {
        return [];
    }

    return prices.map(({ type, finalPriceInclTax }) => ({
        type,
        price: finalPriceInclTax,
    }));
};

const formatColorConfigurations = (colorConfigurations: IFeature[]): IAdditionalColor[] => {
    if (!colorConfigurations) {
        return [];
    }

    return colorConfigurations.map(({ id, pricesV2 }) => ({
        code: id,
        prices: formatPrices(pricesV2),
    }));
};

const formatSelectedColors = (car: ICarDetail): IAdditionalColor[] => {
    if (!car?.interiorColour || !car?.exteriorColour) {
        return [];
    }

    return [
        {
            code: car.interiorColour.id,
            prices: formatPrices(car.interiorColour.pricesV2),
        },
        {
            code: car.exteriorColour.id,
            prices: formatPrices(car.exteriorColour.pricesV2),
        },
    ];
};

const parseFinanceWidgetParameters = (parameters: IFinanceWidgetParameters): IFinanceWidgetParametersParsed => {
    let toReturn = {
        ...parameters,
    } as IFinanceWidgetParametersParsed;
    const duration = parameters.duration ? Number(parameters.duration) : undefined;

    if (duration > 0) {
        toReturn = {
            ...toReturn,
            duration,
        };
    }

    return toReturn;
};

export const transformFinancePriceListRequestData = (
    currentCar: ICarDetail,
    parameters: IFinanceWidgetParameters,
    configurator: IConfiguratorState,
    engineConfigurations: ICarDetail[]
): IGetPricesListRequestData => {
    const { id, externalId, language, market, options } = currentCar;
    const colors = [...configurator.exteriorColors, ...configurator.interiorColors];

    const formattedColors = formatColorConfigurations(colors);
    // @NOTE if there is no data in configurator, use the selected colors
    const additionalColors = formattedColors.length ? formattedColors : formatSelectedColors(currentCar);

    const optionsIds = options
        .map((option: IOption) => option.id)
        .sort((optionA: string, optionB: string) => (optionA < optionB ? -1 : 1))
        .join('+');
    const vehicles = configurator.motorizations
        .filter((motor) => !!motor.isAvailable)
        .map((motor) => {
            const basedMotorId = motor._id || motor.externalId;
            const motorId = `${basedMotorId?.split('+')[0]}+${externalId?.split('+')[1]}+${externalId?.split('+')[2]}${
                options?.length > 0 ? '+' : ''
            }${optionsIds}`;

            return {
                code: motorId,
                ...(externalId === motorId ? { additionalColors } : {}),
            };
        });

    const { productKey, duration, deposit, annualMileage, depositAmountKind, depositAmountNature, services } =
        parseFinanceWidgetParameters(parameters);

    return {
        brand: BRAND,
        language,
        market,
        vehicles,
        parameters: {
            financeProductId: productKey,
            term: duration,
            deposit,
            depositAmount: deposit,
            mileage: annualMileage,
            ...(depositAmountKind && { depositAmountKind }),
            ...(depositAmountNature && { depositAmountNature }),
            ...(services && { services: services }),
        },
        ...(services && { services: services }),
    };
};

export const getStockOptionsData = (options: IOption[]): IVehicleOption[] => {
    if (!options) {
        return null;
    }

    return options.map((option) => {
        return {
            code: option.id,
            pricing: {
                basicPriceInclTax: getCatalogOptionOrColorPrice(option),
                netPriceInclTax: getTotalOptionOrColorPrice(option, FINANCE),
            },
        };
    });
};

export const getOptionData = (options: any[], optionId: string, includeWithoutTaxPrices?: boolean): IVehicleOption => {
    if (!options) {
        return null;
    }

    const option = options.find((option) => option.id === optionId);

    if (!option) {
        return null;
    }

    return {
        code: option.id,
        pricing: {
            basicPriceInclTax: getCatalogOptionOrColorPrice(option),
            netPriceInclTax: getTotalOptionOrColorPrice(option, FINANCE),
            ...(includeWithoutTaxPrices && { basicPriceExclTax: getCatalogOptionOrColorPriceWithoutTax(option) }),
            ...(includeWithoutTaxPrices && { netPriceExclTax: getTotalOptionOrColorPriceWithoutTax(option, FINANCE) }),
        },
    };
};

export const formatColorToAccessory = (
    color: ICarDetail['interiorColour'],
    includeWithoutTaxPrices?: boolean
): IVehicleOption => {
    return {
        code: color.id,
        pricing: {
            basicPriceInclTax: getCatalogOptionOrColorPrice(color),
            netPriceInclTax: getTotalOptionOrColorPrice(color, FINANCE),
            ...(includeWithoutTaxPrices && { basicPriceExclTax: getCatalogOptionOrColorPriceWithoutTax(color) }),
            ...(includeWithoutTaxPrices && { netPriceExclTax: getTotalOptionOrColorPriceWithoutTax(color, FINANCE) }),
        },
    };
};

export const formatFinanceQuotePostRequestData = (
    carDetails: ICarDetail,
    parameters: IFinanceWidgetParameters,
    currency: string,
    currentDeal?: IDeal
) => {
    const service = parameters.services?.[0];
    const parametersParsed = parseFinanceWidgetParameters(parameters as IFinanceWidgetParameters);
    let customerType;
    if (currentDeal?.extraFields?.promoCode) {
        const promoCode = JSON.parse(currentDeal?.extraFields?.promoCode);

        if (promoCode && promoCode.customerType) {
            customerType = promoCode.customerType;
        }
    }

    const financePackage = service
        ? { financePackageKey: service.packageCode, packageCode: service.packageCode, serviceCode: service.serviceCode }
        : {};

    return {
        financeGatewayParameters: parametersParsed,
        carConfigId: carDetails._id ?? carDetails.externalId,
        financeProductKey: parametersParsed.productKey,
        annualMileage: parametersParsed.annualMileage,
        duration: parametersParsed.duration,
        deposit: parametersParsed.deposit,
        isPersonalized: Object.values(parametersParsed).length !== 0,
        currency,
        customerType,
        depositAmountKind: parametersParsed.depositAmountKind,
        ...financePackage,
    };
};
export const getAllVehicleMotorizations = (
    currentCar: ICarDetail,
    cars: ICarDetail[],
    engineSlugs: ICarDetail['engineSlugs']
) => {
    const ids = Object.values(engineSlugs)?.map?.(({ engine }: any) => engine) ?? [];

    const matchingMotorizations = (cars || []).filter(
        (car: ICarDetail) =>
            car.exteriorColourSlug === currentCar.exteriorColourSlug &&
            car.interiorColourSlug === currentCar.interiorColourSlug &&
            ids.includes(car.engine.id)
    );

    if (!matchingMotorizations.length) {
        return [currentCar];
    }

    const isCurrentCarInArray = !!matchingMotorizations.find(
        (motorization) => (motorization._id ?? motorization.externalId) === (currentCar._id ?? currentCar?.externalId)
    );

    if (!isCurrentCarInArray) {
        return [...matchingMotorizations, currentCar];
    }

    return matchingMotorizations.reduce((agg: ICarDetail[], motorization: ICarDetail) => {
        if ((motorization._id ?? motorization.externalId) !== (currentCar._id ?? currentCar?.externalId)) {
            return [...agg, motorization];
        } else {
            return [...agg, currentCar];
        }
    }, []);
};

export const filterOutCashOnlyVehicles = (cars: ICarDetail[]): ICarDetail[] => {
    return cars.filter(
        (car) => car.prices.find((price: IPrice) => price.type === EMPLOYEE_PRICE_TYPE)?.monthlyPrices?.length
    );
};

export const formatDisplayLine = (
    displayValue: string,
    value: string,
    t: TFunction,
    formatMoney: (
        amount: number | string,
        suppressDecimals: boolean,
        formatPattern?: string,
        priceRounding?: PriceRounding
    ) => ReactNode,
    amountRounding?: PriceRounding
) => {
    if (value && displayValue.includes(t('price.currency.sign'))) {
        return formatMoney(value, false, '', null);
    } else {
        return displayValue;
    }
};

export const parseFinanceWidgetOutput = (output: IFinanceWidgetOutput) => {
    return {
        ...output,
        parameters: parseFinanceWidgetParameters(output.parameters),
    };
};

export const buildOtrCostsIncluded = (tfm?: ITFMItem[], transportationCostInclTax?: number) => {
    let otrCostsIncluded = {};

    if (!isMarketIT && transportationCostInclTax) {
        otrCostsIncluded = {
            netTransportfees: {
                amountInclTax: transportationCostInclTax,
            },
        };
    }

    if (tfm) {
        const MRT = tfm.find((item) => item.code === 'MRT');
        const FMR = tfm.find((item) => item.code === 'FMR');
        otrCostsIncluded = {
            ...otrCostsIncluded,
            variousCosts: {
                amountInclTax: MRT?.amount,
            },
            variousCostsTaxExempt: FMR?.amount,
        };
    }

    return otrCostsIncluded;
};

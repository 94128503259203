import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
    IMonthlyPrice,
    IPrice,
    IPriceBaseMonthly,
    IPriceV2,
    IPriceV2Types,
    ITotalPrice,
    PROMO_CODE_DISCOUNT_KEYS,
} from '../services';
import { IOption, IVehicleTransformed } from '../services/stock/models/stockItems.service.model';
import { CATALOGUE_PRICE_TYPE, EMPLOYEE_PRICE_TYPE, FINANCE } from '../services/filters/filters';
import { IPriceStock } from '../services/stock/models/stockItem.price.model';
import { IFinancePricesCalculations, IOptionPrice, IPricesCalculations, IPromotionalText } from '../interfaces/Price';
import { GlobalTexts, IFinanceQuote } from '../services/calculateSummary/calculateSummary.types';
import { IFinanceWidgetParameters } from '../redux/financeWidget/financeWidget.duck.interface';
import { IduckState } from '../redux/carDetails/carDetails.duck.interface';
import { IPriceResponse } from '../services/financePrices/financePrices.types';
import { ICarOptionsList } from '@components/CheckoutMySummary/interfaces';
import { TBudgetType } from '../interfaces/IFilters';
import { TAX_PERCENTAGE, isMarketIT } from '../constants/main';
import { CURRENCY } from '../constants/enums';
import format from '@utils/NumberFormat';
import { captureException } from './sentry.utils';

import sanitize from 'sanitize-html';
import { IDealerFee } from '@components/DealerFees/DealerFeesTemplate';
import { PaymentJourneyTypes } from '../partExchange/interfaces/Default';
import { IConfigurableCar, IDeal } from '../interfaces/Car';

const bracketsRegex = new RegExp(/\[(.*?)\]/, 'g');
// PRIVATE
const isPriceString = (price: string | number): price is string => typeof price === 'string';

const getCurrencyFromPriceByType = (prices: IPrice[], type: string) =>
    prices?.find((price) => price.type === type).currency;

export const hasFinanceVariants = (prices: IPrice[]) =>
    prices ? !!prices?.find((price) => price.type === EMPLOYEE_PRICE_TYPE)?.monthlyPrices?.length : false;

const getBasePriceTypeByPaymentJourney = (paymentJourney: TBudgetType) =>
    paymentJourney === FINANCE ? IPriceV2Types.B2C_FINANCE : IPriceV2Types.B2C_CASH;

// PUBLIC
const DECIMAL_NUMBER_REGEX = /\d+\.?\d*/;
const DECIMAL_NUMBER_REGEX_WITH_BRACES = /{\d+\.?\d*}/;
const PRICE_REGEX = new RegExp(`((\\d)+(\\.|,)?)+${CURRENCY.EUR}`);

export const transformPromotionalText = (
    basePromotionalText: string,
    formatPattern: string,
    amountText?: string | number,
    testId?: string,
    customRegex?: RegExp,
    suffix?: string,
    args: string[] = [],
    parameters: Record<string, string | number> = {}
) => {
    if (!basePromotionalText) {
        return null;
    }

    const currentRegex = customRegex || DECIMAL_NUMBER_REGEX_WITH_BRACES;
    let amount: any = null;
    let basePromotionalTextParts: any;
    const match = basePromotionalText.match(currentRegex);

    if (match) {
        // Add currency symbol to promotional text
        amount = amountText !== undefined && amountText !== null ? amountText : match?.[0];

        basePromotionalTextParts = basePromotionalText.split(currentRegex);
    } else {
        basePromotionalTextParts = [basePromotionalText, ''];
    }

    let OPTIONAL_COMMA = '';
    const secondPart = basePromotionalTextParts[basePromotionalTextParts.length - 1];
    if (secondPart.indexOf(',') === 0) {
        basePromotionalTextParts[basePromotionalTextParts.length - 1] = secondPart.substr(1);
        OPTIONAL_COMMA = ',';
    }

    basePromotionalTextParts[basePromotionalTextParts.length - 1] = `${basePromotionalTextParts[
        basePromotionalTextParts.length - 1
    ].replace(',', '')}`;

    let filledArguments = false;
    if (args[0] === 'apr') {
        amount = parameters['apr'];
        suffix = '%';
        filledArguments = true;
    }

    const PromotionalText = () => {
        switch (true) {
            case filledArguments: {
                return null;
            }
            default: {
                return (
                    <span
                        className="promotionalText"
                        dangerouslySetInnerHTML={{
                            __html: `${basePromotionalTextParts[0]?.trim?.()}&nbsp;`,
                        }}
                    />
                );
            }
        }
    };

    return (
        <div className="promotionalTextWrapper">
            <div>
                <PromotionalText />
                {amount !== null && (
                    <span className="amount" data-testid={testId}>
                        {format(formatPattern, amount)}
                        {suffix || CURRENCY.EUR}
                        {OPTIONAL_COMMA}
                        <span dangerouslySetInnerHTML={{ __html: `&nbsp;` }} />
                    </span>
                )}
            </div>
            <span
                className="label"
                dangerouslySetInnerHTML={{
                    __html: basePromotionalTextParts[basePromotionalTextParts.length - 1],
                }}
            />
        </div>
    );
};

export const parsePromotionalText = (
    promotionalText: IPromotionalText,
    formatPattern: string,
    amountText?: string | number,
    testId?: string,
    customRegex?: RegExp,
    suffix?: string,
    parameters: Record<string, string | number> = {}
) => {
    let primary;
    let secondary: string | Array<{ text: string }>;
    if (isEmpty(promotionalText?.primaryText)) {
        return {
            primary,
            secondary,
        };
    }

    if (typeof promotionalText.primaryText === 'string') {
        primary = transformPromotionalText(
            sanitize(promotionalText.primaryText.replace(bracketsRegex, '<$1>')) as string,
            formatPattern,
            amountText,
            testId,
            customRegex || DECIMAL_NUMBER_REGEX,
            suffix,
            [],
            parameters
        );
        secondary = promotionalText.secondaryText;
    } else {
        let regex = customRegex;

        // On IT market multiple decimals are returned within a primaryText from FC
        // We match only the number which is followed by a currency symbol
        if (isMarketIT && !promotionalText.primaryText?.[0]?.arguments) {
            regex = new RegExp(PRICE_REGEX, 'g');
        }

        primary = transformPromotionalText(
            sanitize(promotionalText.primaryText?.[0]?.text.replace(bracketsRegex, '<$1>')),
            formatPattern,
            amountText,
            testId,
            regex,
            suffix,
            promotionalText.primaryText?.[0]?.arguments ?? [],
            parameters
        );

        secondary = promotionalText.primaryText?.filter((_, i) => i > 0) || promotionalText.secondaryText;
    }

    secondary = Array.isArray(secondary)
        ? secondary.map(({ text }) => ({
              text: isMarketIT ? sanitize(text?.trim()?.replace(bracketsRegex, '<$1>')) : text?.trim?.(),
          }))
        : secondary?.trim?.();

    return {
        primary,
        secondary,
    };
};

/*
 Current promotional text object structure

promotionalText: {primaryText: [{arguments: ["firstRental"], text: "Après un premier loyer de {0}"},…],…}
    primaryText: [{arguments: ["firstRental"], text: "Après un premier loyer de {0}"},…]
        0: {arguments: ["firstRental"], text: "Après un premier loyer de {0}"}
        1: {text: "Vous bénéficiez du bonus écologique sous réserve d’éligibilité."}
    secondaryText: "Vous bénéficiez du bonus écologique sous réserve d’éligibilité."

*/
export const renderPromotionalText = (
    promotionalText: IPromotionalText,
    formatPattern: string,
    amountText?: string | number,
    testId?: string,
    customRegex?: RegExp,
    suffix?: string,
    offerValidityDateString?: string,
    parameters: Record<string, string | number> = {}
) => {
    if (!promotionalText) {
        return null;
    }
    const { primary, secondary } = promotionalText
        ? parsePromotionalText(promotionalText, formatPattern, amountText, testId, customRegex, suffix, parameters)
        : null;

    const Secondary = () => {
        switch (true) {
            case Boolean(secondary) && Array.isArray(secondary): {
                return (
                    <div className="promotionalTextWrapper">
                        {(secondary as Array<{ text: string }>).map(({ text }, key) =>
                            isMarketIT ? (
                                <span
                                    key={`${1 + key}`}
                                    dangerouslySetInnerHTML={{ __html: text }}
                                    className="promotionalText"
                                    data-testid={key === 0 ? 'TESTING_SELECTOR_DEPOSIT_AMOUNT' : ''}
                                />
                            ) : (
                                <span
                                    key={`${1 + key}`}
                                    className="promotionalText"
                                    data-testid={key === 0 ? 'TESTING_SELECTOR_DEPOSIT_AMOUNT' : ''}
                                >
                                    {text}
                                </span>
                            )
                        )}
                    </div>
                );
            }

            case Boolean(secondary): {
                return (
                    <>
                        <span className="promotionalText" data-testid="TESTING_SELECTOR_DEPOSIT_AMOUNT">
                            {secondary}
                        </span>
                    </>
                );
            }

            default:
                return null;
        }
    };

    return primary || secondary ? (
        <>
            {primary && primary}
            <Secondary />
            {offerValidityDateString && (
                <>
                    <span className="promotionalText">{offerValidityDateString}</span>
                </>
            )}
        </>
    ) : null;
};

export const getFinanceWidgetParams = (financeQuote: IFinanceQuote): IFinanceWidgetParameters => {
    if (financeQuote?.financeGatewayParameters) {
        return financeQuote?.financeGatewayParameters;
    }

    return {
        annualMileage: financeQuote?.annualMileage,
        deposit: financeQuote?.deposit,
        duration: financeQuote?.duration,
        productKey: financeQuote?.financeProductKey,
        services: financeQuote?.financeGatewayParameters.services,
        depositAmountKind: financeQuote?.depositAmountKind,
    };
};

export const hasMonthlyPriceByType = (prices: IPrice[], type: string) =>
    prices?.find((price) => price.type === type).monthlyPrices.length > 0;

export const getMonthlyPriceObjByType = <T extends IPriceBaseMonthly>(
    prices: T[],
    type: string
): IMonthlyPrice | null => prices?.find((price: T) => price.type === type)?.monthlyPrices?.[0] ?? null;

export const getFinanceDetailView = (prices: IPrice[] | IPriceStock[], type: string) =>
    (prices as any)?.find((price: IPrice | IPriceStock) => price.type === type).financeDetailView;

export const getFinanceDetailText = (
    prices: IPrice[] | IPriceStock[],
    type: string,
    globalTexts: GlobalTexts | null,
    pricesList: IPriceResponse[] | null,
    vehicleId: string | null
) => {
    if (globalTexts && globalTexts?.callToAction?.detailText) {
        return globalTexts.callToAction.detailText;
    } else if (pricesList && vehicleId) {
        return pricesList.find((pl) => pl.code === vehicleId)?.financeDetailText || pricesList[0]?.financeDetailText;
    }
    return (prices as any)?.find((price: IPrice | IPriceStock) => price.type === type).financeDetailText;
};

export const getFinancePersonalizeText = (prices: IPrice[] | IPriceStock[], type: string) =>
    (prices as any)?.find((price: IPrice | IPriceStock) => price.type === type).financePersonalizeText;

export const getPriceByType = (prices: IPrice[], type: string): number => {
    return prices?.find((price) => price.type === type).basePrice;
};

export const getFullPriceByType = (prices: IPrice[], type: string): IPrice => {
    return prices?.find((price) => price.type === type);
};

export const getTotalPriceObjByType = (prices: any, type: string) => {
    return prices?.find((price: any) => price.type === type);
};

export const getTotalOptionOrColorPrice = (item: IOption | ICarOptionsList, paymentJourney: TBudgetType) =>
    !item.pricesV2
        ? 0
        : item.pricesV2?.find((price: IPriceV2) => price.type === getBasePriceTypeByPaymentJourney(paymentJourney))
              ?.finalPriceInclTax;

export const getTotalOptionOrColorPriceWithoutTax = (item: IOption | ICarOptionsList, paymentJourney: TBudgetType) =>
    !item.pricesV2
        ? 0
        : item.pricesV2?.find((price: IPriceV2) => price.type === getBasePriceTypeByPaymentJourney(paymentJourney))
              ?.finalPriceExclTax;

export const getCatalogOptionOrColorPrice = (item: IOption) =>
    !item.pricesV2
        ? 0
        : item.pricesV2?.find((price: IPriceV2) => price.type === IPriceV2Types.CATALOG_FINANCE)?.finalPriceInclTax;

export const getCatalogOptionOrColorPriceWithoutTax = (item: IOption) =>
    !item.pricesV2
        ? 0
        : item.pricesV2?.find((price: IPriceV2) => price.type === IPriceV2Types.CATALOG_FINANCE)?.finalPriceExclTax;

export const getMonthlyOptionOrColorPrice = (item: IOption) =>
    !item.prices
        ? 0
        : item.prices?.find((price: IPriceStock) => price.type === EMPLOYEE_PRICE_TYPE).monthlyPrices?.[0]?.amount;

export const calculateCatalogPrices = (
    { prices, pricesV2: rootPricesV2, extraFields }: IduckState['currentCarDetails'],
    isSOLEnabled: boolean
): { totalPrice: number; totalCashPriceExclTax: number; totalCashPriceInclTax: number } => {
    const pricesV2 = rootPricesV2 || extraFields?.pricesV2;
    const catalogPrices = getTotalPriceObjByType(prices, CATALOGUE_PRICE_TYPE)?.basePrice;
    const totalPriceNonGB = pricesV2
        ? getTotalPriceObjByType(pricesV2, IPriceV2Types.TOTAL_CATALOG_CASH)?.finalPriceInclTax
        : catalogPrices;
    const totalPrice = isSOLEnabled
        ? pricesV2
            ? getTotalPriceObjByType(pricesV2, IPriceV2Types.TOTAL_B2C_CASH)?.breakdown.totalCatalogPriceOnTheRoad
            : catalogPrices
        : totalPriceNonGB;
    return {
        totalPrice,
        totalCashPriceExclTax: getTotalPriceObjByType(pricesV2, IPriceV2Types.CATALOG_CASH)?.finalPriceExclTax,
        totalCashPriceInclTax: getTotalPriceObjByType(pricesV2, IPriceV2Types.CATALOG_CASH)?.finalPriceInclTax,
    };
};

export const getStorePriceByPaymentMethod = (
    currentDeal: IDeal,
    paymentMethod: string,
    isSOLEnabled: boolean
): number | undefined => {
    const pricesV2 =
        currentDeal?.fullProductConfiguration?.pricesV2 || currentDeal?.fullProductConfiguration?.extraFields?.pricesV2;

    const priceTypeByPaymentJourney =
        !paymentMethod || paymentMethod === PaymentJourneyTypes.CASH
            ? IPriceV2Types.TOTAL_B2C_CASH
            : IPriceV2Types.TOTAL_B2C_FINANCE;

    const priceObj = getTotalPriceObjByType(pricesV2, priceTypeByPaymentJourney);

    return isSOLEnabled && priceObj?.breakdown && Object.keys(priceObj?.breakdown)?.length > 1
        ? priceObj.breakdown.finalCustomerCashPriceOnTheRoad || priceObj.breakdown.finalCustomerPriceOnTheRoad
        : priceObj.finalPriceInclTax;
};

export const calculateCashPrices = (
    {
        extraFields,
        interiorColour,
        exteriorColour,
        options,
        pricesV2: rootPricesV2,
        prices,
        fees,
        totalPrices,
    }: IduckState['currentCarDetails'],
    paymentJourney: TBudgetType = FINANCE,
    isSOLEnabled: boolean,
    dealerDiscount: number = 0,
    hasSelectedBudgetType: boolean = false,
    ignoreFees: boolean = false,
    isDealerFeesFsEnabled?: boolean
): IPricesCalculations => {
    // totalPrices
    const pricesV2 = rootPricesV2 || extraFields?.pricesV2;

    const totalPriceSummary =
        hasSelectedBudgetType && paymentJourney === FINANCE
            ? totalPrices?.find((price: ITotalPrice) => price?.type === IPriceV2Types.B2C_FINANCE)
            : totalPrices?.find((price: ITotalPrice) => price?.type === IPriceV2Types.B2C_CASH);

    // pricesV1 -> default to prod
    if (!pricesV2) {
        const basicCarPriceObj: IPrice = getTotalPriceObjByType(prices, EMPLOYEE_PRICE_TYPE);
        const interiorColorPriceObj = getTotalPriceObjByType(interiorColour?.prices, EMPLOYEE_PRICE_TYPE);
        const exteriorColorPriceObj = getTotalPriceObjByType(exteriorColour?.prices, EMPLOYEE_PRICE_TYPE);

        const interiorColorPrice = isPriceString(interiorColorPriceObj?.basePrice)
            ? parseInt(interiorColorPriceObj?.basePrice)
            : interiorColorPriceObj?.basePrice;
        const exteriorColorPrice = isPriceString(exteriorColorPriceObj?.basePrice)
            ? parseInt(exteriorColorPriceObj?.basePrice)
            : exteriorColorPriceObj?.basePrice;
        const optionsPrices = options
            ? options
                  .map((option: IOption) => {
                      const price = getTotalPriceObjByType(option.prices, EMPLOYEE_PRICE_TYPE);

                      return {
                          id: option.id,
                          price: price.basePrice,
                      };
                  })
                  .filter(Boolean)
            : [];

        const optionsTotalPrice = optionsPrices.reduce(
            (total: number, option: any) =>
                total + (isPriceString(option.price) ? parseInt(option.price) : option.price),
            0
        );

        const totalPrice = basicCarPriceObj?.basePrice;
        const basePrice = totalPrice - optionsTotalPrice - interiorColorPrice - exteriorColorPrice - dealerDiscount;
        //fallback price because of old deal on peugeot
        const fallbackPrice = totalPrice - basicCarPriceObj?.extras.salesDiscount;

        return {
            basePrice,
            totalPrice,
            currency: basicCarPriceObj?.currency,
            summary: {
                vehiclePrice: fallbackPrice,
                finalPriceWithAccessories: fallbackPrice,
                finalPriceWithAccessoriesAndDiscounts: fallbackPrice,
            },
            components: {
                interiorColorPrice,
                exteriorColorPrice,
                options: optionsPrices,
                optionsTotalPrice,
            },
        };
    }

    // priceV2
    const basicPrices = pricesV2 || extraFields.pricesV2;

    const basicCarPriceObjTotalB2C: IPriceV2 = getTotalPriceObjByType(
        basicPrices,
        paymentJourney === FINANCE && hasSelectedBudgetType
            ? IPriceV2Types.TOTAL_B2C_FINANCE
            : IPriceV2Types.TOTAL_B2C_CASH
    );

    const basicCarPriceObjB2C: IPriceV2 = getTotalPriceObjByType(
        pricesV2 || extraFields.pricesV2,
        paymentJourney === FINANCE && hasSelectedBudgetType ? IPriceV2Types.B2C_FINANCE : IPriceV2Types.B2C_CASH
    );

    const interiorColorPrice = getTotalPriceObjByType(
        interiorColour.pricesV2,
        getBasePriceTypeByPaymentJourney(paymentJourney)
    ).finalPriceInclTax;
    const exteriorColorPrice = getTotalPriceObjByType(
        exteriorColour.pricesV2,
        getBasePriceTypeByPaymentJourney(paymentJourney)
    ).finalPriceInclTax;
    const optionsPrices = options
        ? options.map((option: IOption) => getTotalOptionOrColorPrice(option, paymentJourney)).filter(Boolean)
        : [];
    const optionsTotalPrice = optionsPrices.reduce((total: number, optionPrice: number) => total + optionPrice, 0);

    const getTotalPrice = (priceObj: IPriceV2) => {
        return isSOLEnabled
            ? priceObj.breakdown.finalCustomerCashPriceOnTheRoad || priceObj.breakdown.finalCustomerPriceOnTheRoad
            : priceObj.finalPriceInclTax;
    };

    const totalPrice = getTotalPrice(basicCarPriceObjTotalB2C);
    const basePrice = totalPrice - optionsTotalPrice - interiorColorPrice - exteriorColorPrice - dealerDiscount;
    const discount = basicCarPriceObjTotalB2C.discounts;
    const promoCodeDiscount = discount?.find(({ key }) => PROMO_CODE_DISCOUNT_KEYS.includes(key));
    const promoCodeDiscountAmount = promoCodeDiscount?.absoluteAmount ?? promoCodeDiscount?.amount ?? 0.0;

    const getTotalPriceWithFees = (price: number) => {
        switch (true) {
            case ignoreFees: {
                return price;
            }

            case totalPriceSummary?.totalFeesInclTax: {
                return price + totalPriceSummary?.totalFeesInclTax;
            }

            case !fees: {
                return price;
            }

            default: {
                return price + getDealerFeesSum(fees, isDealerFeesFsEnabled).withVAT;
            }
        }
    };

    return {
        basePrice: basePrice + promoCodeDiscountAmount,
        totalBasicPrice: getTotalPriceObjByType(basicPrices, IPriceV2Types.TOTAL_B2C_CASH)?.finalPriceInclTax,
        totalBasicPriceWithoutVat:
            getTotalPriceObjByType(basicPrices, IPriceV2Types.TOTAL_B2C_CASH)?.finalPriceExclTax +
            getDealerFeesSum(fees, isDealerFeesFsEnabled).withoutVAT,
        totalPrice: getTotalPriceWithFees(totalPrice),
        totalPriceWithoutPaymentJourney: {
            cash: getTotalPriceWithFees(
                getTotalPrice(getTotalPriceObjByType(basicPrices, IPriceV2Types.TOTAL_B2C_CASH))
            ),
            finance: getTotalPriceWithFees(
                getTotalPrice(getTotalPriceObjByType(basicPrices, IPriceV2Types.TOTAL_B2C_FINANCE))
            ),
        },
        leasys: {
            netPriceExclTax: getTotalPriceObjByType(basicPrices, IPriceV2Types.B2C_CASH)?.finalPriceExclTax,
            netPriceInclTax: getTotalPriceObjByType(basicPrices, IPriceV2Types.B2C_CASH)?.finalPriceInclTax,
        },
        currency: basicCarPriceObjTotalB2C.currency,
        summary: {
            vehiclePrice: basicCarPriceObjB2C.inputPriceInclTax + promoCodeDiscountAmount,
            finalPriceWithoutAccessories:
                basicCarPriceObjTotalB2C.localNetPriceInclTax + totalPriceSummary?.totalAccessoriesPriceInclTax,
            finalPriceWithAccessories:
                basicCarPriceObjTotalB2C.localNetPriceInclTax +
                totalPriceSummary?.totalAccessoriesPriceInclTax +
                (totalPriceSummary?.totalFeesInclTax || 0),
            finalPriceWithAccessoriesAndDiscounts: totalPriceSummary?.totalAmount,
        },
        components: {
            interiorColorPrice,
            exteriorColorPrice,
            options: optionsPrices,
            optionsTotalPrice,
        },
    };
};

export const calculateDefaultFinancePrices = (
    { prices, interiorColour, exteriorColour, options, pricesV2, extraFields }: IduckState['currentCarDetails'],
    paymentJourney: TBudgetType
): IFinancePricesCalculations => {
    const basicCarPriceObj = getMonthlyPriceObjByType(prices, EMPLOYEE_PRICE_TYPE);
    const interiorColorPriceObj = getMonthlyPriceObjByType(interiorColour?.prices, EMPLOYEE_PRICE_TYPE);
    const exteriorColorPriceObj = getMonthlyPriceObjByType(exteriorColour?.prices, EMPLOYEE_PRICE_TYPE);

    const interiorColorPrice = isPriceString(interiorColorPriceObj?.amount)
        ? parseInt(interiorColorPriceObj?.amount)
        : interiorColorPriceObj?.amount;
    const exteriorColorPrice = isPriceString(exteriorColorPriceObj?.amount)
        ? parseInt(exteriorColorPriceObj?.amount)
        : exteriorColorPriceObj?.amount;
    const optionsPrices = options ? options.map(getMonthlyOptionOrColorPrice).filter(Boolean) : [];
    const optionsTotalPrice = optionsPrices.reduce((total: number, optionPrice: number) => total + optionPrice, 0);

    const totalPrice = basicCarPriceObj?.amount;
    const basePrice = totalPrice - optionsTotalPrice - interiorColorPrice - exteriorColorPrice;
    const priceTypeByPaymentJourney =
        paymentJourney === PaymentJourneyTypes.FINANCE ? IPriceV2Types.TOTAL_B2C_FINANCE : IPriceV2Types.TOTAL_B2C_CASH;
    const storePrice =
        getTotalPriceObjByType(pricesV2 ?? extraFields?.pricesV2 ?? [], priceTypeByPaymentJourney)?.breakdown
            ?.finalCustomerPriceOnTheRoad ??
        getTotalPriceObjByType(pricesV2 ?? extraFields?.pricesV2 ?? [], priceTypeByPaymentJourney)?.finalPriceInclTax;

    return {
        basePrice,
        totalPrice,
        storePrice,
        currency: getCurrencyFromPriceByType(prices, EMPLOYEE_PRICE_TYPE),
        components: {
            interiorColorPrice,
            exteriorColorPrice,
            options: optionsPrices,
            optionsTotalPrice,
        },
        financeProperties: {
            apr: basicCarPriceObj?.apr ?? 0,
            tin: basicCarPriceObj?.tin ?? 0,
            deposit: basicCarPriceObj?.depositAmount ?? basicCarPriceObj?.deposit,
            duration: basicCarPriceObj?.lengthOfTerm,
            annualMileage: basicCarPriceObj?.mileage,
            financeProductKey: basicCarPriceObj?.financeProductId,
            hasFinanceVariants: hasFinanceVariants(prices),
            hasMonthlyPrices: hasMonthlyPriceByType(prices, EMPLOYEE_PRICE_TYPE),
            finalPayment: basicCarPriceObj?.finalPayment ?? 0,
        },
    };
};

export const calculateFinancePrices = (
    carDetails: IduckState['currentCarDetails'],
    cashPrices: IPricesCalculations,
    financeQuote: IFinanceQuote,
    pricesList: IPriceResponse[] = [],
    paymentJourney: TBudgetType
): IFinancePricesCalculations => {
    const currentConfigurationPrices = pricesList?.find(
        (price) => price.code === (carDetails.id ?? carDetails.externalId)
    );

    const interiorColorRaw =
        currentConfigurationPrices?.additionalColors?.find?.((feature) => carDetails.interiorColour.id == feature.code)
            ?.price ?? 0;
    const interiorColorPrice = isPriceString(interiorColorRaw) ? parseInt(interiorColorRaw) : interiorColorRaw;
    const exteriorColorRaw =
        currentConfigurationPrices?.additionalColors?.find?.((feature) => carDetails.exteriorColour.id == feature.code)
            ?.price ?? 0;
    const exteriorColorPrice = isPriceString(exteriorColorRaw) ? parseInt(exteriorColorRaw) : exteriorColorRaw;
    const optionPrices =
        carDetails.options?.map(({ id }: any) => {
            const optionPrice =
                currentConfigurationPrices?.additionalColors?.find?.((feature) => feature.code === id)?.price ?? 0;

            return {
                id: id,
                price: optionPrice,
            };
        }) ?? [];
    const optionsTotalPrice = optionPrices.reduce(
        (total: number, option: IOptionPrice) =>
            total + (isPriceString(option.price) ? parseInt(option.price) : option.price),
        0
    );

    const totalPrice = currentConfigurationPrices?.price || financeQuote.monthlyPayment;
    const basePrice = totalPrice - exteriorColorPrice - interiorColorPrice - optionsTotalPrice;
    const prices = carDetails?.pricesV2 || carDetails?.extraFields?.pricesV2;
    const priceTypeByPaymentJourney =
        paymentJourney === PaymentJourneyTypes.FINANCE ? IPriceV2Types.TOTAL_B2C_FINANCE : IPriceV2Types.TOTAL_B2C_CASH;
    const pricesFinance = getFullPriceByType(prices, priceTypeByPaymentJourney);
    const storePrice = pricesFinance?.breakdown?.finalCustomerPriceOnTheRoad || pricesFinance?.finalPriceInclTax;
    const monthlyPriceObj = getMonthlyPriceObjByType(carDetails.prices, EMPLOYEE_PRICE_TYPE);

    return {
        basePrice,
        totalPrice,
        storePrice,
        currency: cashPrices.currency,
        components: {
            interiorColorPrice,
            exteriorColorPrice,
            options: optionPrices,
            optionsTotalPrice,
        },
        financeProperties: {
            apr: financeQuote.apr ?? monthlyPriceObj?.apr,
            tin: financeQuote.tin ?? monthlyPriceObj?.tin,
            deposit: financeQuote.firstRental ?? financeQuote.deposit,
            duration: financeQuote.duration,
            annualMileage: financeQuote.annualMileage,
            financeProductKey: financeQuote.financeProductKey,
            hasFinanceVariants: hasFinanceVariants(carDetails.prices),
            hasMonthlyPrices: hasMonthlyPriceByType(carDetails.prices, EMPLOYEE_PRICE_TYPE),
            finalPayment: financeQuote ? financeQuote?.finalPayment : monthlyPriceObj?.finalPayment,
        },
    };
};

// TODO remove later
export function formatMoneyValue(amount: any, currencySign = '€', decimalCount = 2, decimal = ',', thousands = ' ') {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? '-' : '';

        const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
        const j = i.length > 3 ? i.length % 3 : 0;
        const d = Math.abs(amount - Number(i))
            .toFixed(decimalCount)
            .slice(2)
            .replace(new RegExp(`^0+$`, 'g'), '');

        const value =
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount && d.length !== 0 ? decimal + d : '');

        return {
            value,
            currencySign,
            text: `${value} ${currencySign}`,
        };
    } catch (error: any) {
        console.error(error);
    }
}

// TODO remove later
export function formatMoney(amount: any, currencySign = '€', decimalCount = 2, decimal = ',', thousands = ' ') {
    try {
        const { value, currencySign: currency } = formatMoneyValue(
            amount,
            currencySign,
            decimalCount,
            decimal,
            thousands
        );

        return (
            <span style={{ whiteSpace: 'nowrap' }}>
                {value}
                <span className="currencyLabel"> {currency}</span>
            </span>
        );
    } catch (e: any) {
        captureException(e);
        console.log(e);
    }
}

export const getDealerFeesSum = (
    fees: IDealerFee[] = [],
    isDealerFeesFsEnabled: boolean
): { withoutVAT: number; withVAT: number } => {
    const feesSum = isDealerFeesFsEnabled
        ? fees.reduce((sum: number, fee: IDealerFee) => {
              if (fee.isUsed === false) return sum;
              return sum + Number(fee.priceInclTax);
          }, 0)
        : 0;

    if (!Number(TAX_PERCENTAGE)) {
        console.warn(`TAX_PERCENTAGE isn't defined! Fee price without VAT won't be calculated properly`);
    }
    return {
        withVAT: feesSum,
        withoutVAT: TAX_PERCENTAGE ? feesSum / (1 + Number(TAX_PERCENTAGE) / 100) : feesSum,
    };
};

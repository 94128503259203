// eslint-disable-next-line no-restricted-imports
import router from 'next/router';
import { ParsedUrlQuery } from 'querystring';

import { ContextWithStore, HandleFiltersResponse } from './@types';
import { handleInitialURLParams } from '../page.utils';
import { getFilterCategories, getFilters, getPriceRange } from '../../services/filters/filterHelpers';
import FilterDuck from '../../redux/filters/filter.duck';
import { FINANCE, SORT_TYPE, TBudgetType } from '../../services';
import {
    filtersExport,
    filtersFromUrl,
    getGeoLocationPropertiesFromURL,
} from '../../services/filters/utils/Filters.utils';
import { FiltersService } from '../../services/filters/filters.service';
import { Store } from 'redux';
import FinanceCalculatorService from '../../services/financeQuote/financePrices.service';
import FinanceWidgetDuck from '../../redux/financeWidget/financeWidget.duck';
import { IFilterRule } from '../../redux/filters/filter.duck.interface';
import { batch } from 'react-redux';
import { FeatureSwitchesContext } from '../../types/featureSwitches';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_CACHE_MAX_AGE, LEASYS_PROVIDER_TYPE } from 'src/constants/main';
import UserDuck from 'src/redux/user/user.duck';
import AuthService from 'src/services/authService/auth.service';
import { SESSION_COOKIE_NAME } from 'src/constants/myAccount';
import { getSessionIdCookie } from '@utils/Session.utils';
import { getCalculateSummary } from '../../services/calculateSummary/calculateSummary.service';

interface IUpdateGeneralContextOpt {
    headers: {
        key: 'Cache-Control';
        value: string;
    }[];
}
export const updateGeneralContext = async (
    ctx: ContextWithStore,
    opt?: IUpdateGeneralContextOpt
): Promise<ContextWithStore> => {
    const isLoggedIn = UserDuck.isLoggedIn(ctx.store.getState());

    if (!isLoggedIn && typeof window === 'undefined') {
        try {
            const isLoggedIdResponse = await AuthService.isLoggedIn(
                ctx?.req?.cookies?.[SESSION_COOKIE_NAME] || getSessionIdCookie()
            );

            if (isLoggedIdResponse?.data) {
                ctx.store.dispatch(
                    UserDuck.actionSetUserLoggedDone({
                        isLoggedIn: isLoggedIdResponse.data.logged_in,
                        loginType: isLoggedIdResponse.data.login_type,
                        detail: isLoggedIdResponse.data.bo_user,
                    })
                );
            }
        } catch (error) {
            console.error('Failed isLoggedInCheck:', error);
        }
    }
    const isAgent = UserDuck.isAgentLogged(ctx.store.getState());

    if (opt?.headers) {
        (opt.headers || []).forEach((header) => {
            if (isAgent && header.key === 'Cache-Control') {
                ctx?.res?.setHeader('Cache-Control', 'no-store, must-revalidate');
            } else {
                ctx?.res?.setHeader(header.key, header.value);
            }
        });
    } else {
        ctx?.res?.setHeader('Cache-Control', 'no-cache');
    }

    return ctx;
};

export const sortAvailableFilterAlphabetically = (a: IFilterRule, b: IFilterRule): number => {
    if (a.parent !== b.parent) {
        if (a.parent > b.parent) {
            return 1;
        }
        if (b.parent > a.parent) {
            return -1;
        }
    } else {
        if (a.name > b.name) {
            return 1;
        }
        if (b.name > a.name) {
            return -1;
        }
    }
    return 0;
};

export const simpleRedirectionGetInitialProps = async (ctx: FeatureSwitchesContext<ContextWithStore>) => {
    const { store, asPath, res, featureContext } = await updateGeneralContext(ctx, {
        headers: [
            {
                key: 'Cache-Control',
                value: `private, no-cache`,
            },
        ],
    });

    try {
        handleInitialURLParams(store, asPath, res, router, featureContext);
    } catch (e: any) {
        console.error(e);
    }

    return {
        namespacesRequired: ['common'],
    };
};

export const handleFilters = async (
    store: Store,
    query: ParsedUrlQuery,
    paymentJourney: TBudgetType,
    nameplateBodyStyleSlug?: string
): Promise<HandleFiltersResponse> => {
    const { geoLocation, distanceRadius, geoLocationName } = getGeoLocationPropertiesFromURL(query);

    console.log('%c FETCH getFilter', 'background: blue; color: white;');
    const allFilters = await FiltersService.getFilter(
        filtersExport(
            paymentJourney
                ? [
                      {
                          name: 'prices',
                          parent: 'prices',
                          displayName:
                              paymentJourney === FINANCE
                                  ? 'filters.category.prices.monthlyPrices.amount'
                                  : 'filters.category.prices.basePrice.global',
                          value: { min: 1, max: 999999 },
                      },
                  ]
                : [],
            paymentJourney
        ),
        paymentJourney,
        nameplateBodyStyleSlug
    );

    if (!allFilters) {
        console.warn('No all filters found!');
        return;
    }
    // Merged filters from store with filters set in URL
    const mergedFilters = filtersFromUrl(getFilters(allFilters.items), query, allFilters.items).sort(
        sortAvailableFilterAlphabetically
    );
    const exportedFiltersWithPrice = filtersExport(mergedFilters, paymentJourney);
    const exportedFiltersWithoutPrice = filtersExport(mergedFilters, paymentJourney, false);

    let applicableAllFilters: any = [];
    let availableFilters = allFilters;

    if (!isEmpty(exportedFiltersWithPrice)) {
        applicableAllFilters = await FiltersService.getFilter(
            exportedFiltersWithPrice,
            paymentJourney,
            nameplateBodyStyleSlug
        );
    }

    if (!isEmpty(applicableAllFilters)) {
        availableFilters = applicableAllFilters;
    }

    const allPriceRange = getPriceRange(allFilters.items, paymentJourney);
    const availablePriceRange = getPriceRange(availableFilters.items, paymentJourney);
    const availablePriceRangeAll = getPriceRange(allFilters.items, paymentJourney);

    batch(() => {
        store.dispatch(FilterDuck.setFilters(mergedFilters, geoLocation, distanceRadius, geoLocationName));
        store.dispatch(FilterDuck.setFilterCategories(getFilterCategories(allFilters.items)));
        store.dispatch(FilterDuck.setFilteredFilters(getFilters(availableFilters.items)));
        store.dispatch(
            FilterDuck.setPriceRange({
                ...allPriceRange,
                availableMin:
                    exportedFiltersWithoutPrice.length === 0 ? availablePriceRangeAll?.min : availablePriceRange?.min,
                availableMax:
                    exportedFiltersWithoutPrice.length === 0 ? availablePriceRangeAll?.max : availablePriceRange?.max,
            })
        );
    });

    return {
        mergedFilters,
        exportedFiltersWithPrice,
        exportedFiltersWithoutPrice,
    };
};

export const handleSorting = (store: Store, query: ParsedUrlQuery): SORT_TYPE => {
    let sorting = FilterDuck.getSorting(store.getState());

    if (query.sort) {
        sorting = query.sort.toString() as SORT_TYPE;
        store.dispatch(FilterDuck.setSorting(sorting));
    }

    return sorting;
};

export const handleLeasysQuote = async (store: Store, carConfigId: string, quotationId: string) => {
    const { data: financeQuote } = await getCalculateSummary({
        isPersonalized: false,
        financeGatewayParameters: null,
        carConfigId,
        providerType: LEASYS_PROVIDER_TYPE,
        ...(quotationId && { quotationId }),
    });
    store.dispatch(FinanceWidgetDuck.setLeasysQuote(financeQuote));
    store.dispatch(FinanceWidgetDuck.setDefaultLeasysQuote(financeQuote));
};

export const handleFinanceQuote = async (store: Store, financeQuoteId?: string) => {
    const currentFinanceQuote = FinanceWidgetDuck.getOwnState(store.getState()).financeQuote;
    if (financeQuoteId && (!currentFinanceQuote?.token || currentFinanceQuote.token !== financeQuoteId)) {
        const { data: financeQuote } = await FinanceCalculatorService.getFinanceQuote(financeQuoteId);
        store.dispatch(FinanceWidgetDuck.setFinanceQuote(financeQuote));
    } else if (!financeQuoteId) {
        store.dispatch(FinanceWidgetDuck.setFinanceQuote(null));
    }
};
